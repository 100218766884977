import React from 'react';
import RelatedCard from './RelatedCard';

export default function RelatedLoreCard(props) {
  const { lore } = props;

  return (
    <RelatedCard
      title={lore.title}
      link={`/lore/${lore.slug}`}
      imageHandle={lore.gallery[0].handle}
      imageTitle={lore.gallery[0].title}
    />
  );
}