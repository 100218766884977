import React from 'react';
// import gql from 'graphql-tag';
// import { graphql } from 'react-apollo';
import Markdown from 'react-markdown';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import LanguageIcon from '@material-ui/icons/Language';

import RelatedPlaceCard from '../components/RelatedPlaceCard';
import RelatedPersonCard from '../components/RelatedPersonCard';
import RelatedLoreCard from '../components/RelatedLoreCard';

import Layout from '../components/Layout';
import SEO, {SEODescription, SEOImage} from '../components/SEO';

import { graphql } from 'gatsby';

import MuiOutboundLink from '../components/MuiOutboundLink';


const useStyles = makeStyles((theme) => ({
  articleContainer: {
    padding: theme.spacing(2),
  },
  section: {
    paddingTop: theme.spacing(2),
  },
  amazonLink: {
    // display: 'flex',
    // alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  }
}));

const TourCompany = ({ data }) => {
  const classes = useStyles();
  const tourCompany = data.gcms.tourCompany;

  return (
    <Layout>

      <SEO 
        title={tourCompany.name}
        description={SEODescription(tourCompany.description.text)}
        image={SEOImage(tourCompany.image.handle)}
        article={true}
      />

      <Paper className={classes.articleContainer}>
        <article>
          <Typography variant="h3" gutterBottom={true}>
            {tourCompany.name}
          </Typography>
          <img
            src={`https://media.graphcms.com/resize=w:1200/${tourCompany.image.handle}`}
            title={tourCompany.image.title}
            style={{ width: '100%' }}
          />
          <Markdown source={tourCompany.description.markdown} escapeHtml={false} />

          {tourCompany.link &&
            <Button
              component={MuiOutboundLink}
              href={tourCompany.link}
              target="_blank" rel="noopener"
              className={classes.amazonLink}
              variant="contained"
              color="secondary"
              startIcon={<LanguageIcon />}
            >
              Website
            </Button>
          }

          <Divider variant="middle" />

          {tourCompany.places.length > 0 &&
            <section className={classes.section}>
              <Typography variant="h5" gutterBottom={true}>
                Places they tour
                </Typography>
              <Grid container spacing={1}>
                {tourCompany.places.map(place => (
                  <RelatedPlaceCard
                    key={place.id}
                    place={place}
                  />
                ))}
              </Grid>
            </section>
          }

          {tourCompany.people.length > 0 &&
            <section className={classes.section}>
              <Typography variant="h5" gutterBottom={true}>
                People they talk about
                </Typography>
              <Grid container spacing={1}>
                {tourCompany.people.map(person => (
                  <RelatedPersonCard
                    key={person.id}
                    person={person}
                  />
                ))}
              </Grid>
            </section>
          }

          {tourCompany.lores.length > 0 &&
            <section className={classes.section}>
              <Typography variant="h5" gutterBottom={true}>
                Stories they cover
                </Typography>
              <Grid container spacing={1}>
                {tourCompany.lores.map(lore => (
                  <RelatedLoreCard
                    key={lore.id}
                    lore={lore}
                  />
                ))}
              </Grid>
            </section>
          }

        </article>
      </Paper>
    </Layout>
  );
};

export const query = graphql`
  query singleTourCompany($slug: String!) {
    gcms {
      tourCompany(where: {slug: $slug}) {
        id
        name
        image {
          handle
          title
        }
        description {
          markdown
          text
        }
        link
        lores {
          id
          title
          slug
          gallery(first: 1) {
            handle
            title
          }
        }
        places {
          id
          name
          slug
          gallery(first: 1) {
            handle
            title
          }
        }
        people {
          id
          name
          slug
          gallery(first: 1) {
            handle
            title
          }
        }
      }
    }
  }
`;

export default TourCompany;

// const TourCompany = ({ data: { loading, error, tourCompany } }) => {
//   const classes = useStyles();

//   if (error) return <h1>Error fetching the tour company!</h1>;
//   if (!loading) {
//     return (
//       <Paper className={classes.articleContainer}>
//         <article>
//           <Typography variant="h3" gutterBottom={true}>
//             {tourCompany.name}
//           </Typography>
//           <img
//             src={`https://media.graphcms.com/resize=w:1200/${tourCompany.image.handle}`}
//             title={tourCompany.image.title}
//             style={{ width: '100%' }}
//           />
//           <Markdown source={tourCompany.description.markdown} escapeHtml={false} />

//           {tourCompany.link &&
//             <Button
//               href={tourCompany.link}
//               target="_blank" rel="noopener"
//               className={classes.amazonLink}
//               variant="outlined"
//               color="primary"
//               startIcon={<LanguageIcon />}
//             >
//               Website
//             </Button>
//           }

//           <Divider variant="middle" />

//           {tourCompany.places.length > 0 &&
//             <section className={classes.section}>
//               <Typography variant="h5" gutterBottom={true}>
//                 Places they tour
//               </Typography>
//               <Grid container spacing={1}>
//                 {tourCompany.places.map(place => (
//                   <RelatedCard
//                     title={place.name}
//                     link={`/place/${place.slug}`}
//                     imageHandle={place.gallery[0].handle}
//                     imageTitle={place.gallery[0].title}
//                   />
//                 ))}
//               </Grid>
//             </section>
//           }

//           {tourCompany.people.length > 0 &&
//             <section className={classes.section}>
//               <Typography variant="h5" gutterBottom={true}>
//                 People they talk about
//               </Typography>
//               <Grid container spacing={1}>
//                 {tourCompany.people.map(person => (
//                   <RelatedCard
//                     title={person.name}
//                     link={`/person/${person.slug}`}
//                     imageHandle={person.gallery[0].handle}
//                     imageTitle={person.gallery[0].title}
//                   />
//                 ))}
//               </Grid>
//             </section>
//           }

//           {tourCompany.lores.length > 0 &&
//             <section className={classes.section}>
//               <Typography variant="h5" gutterBottom={true}>
//                 Stories they cover
//               </Typography>
//               <Grid container spacing={1}>
//                 {tourCompany.lores.map(lore => (
//                   <RelatedCard
//                     title={lore.title}
//                     link={`/lore/${lore.slug}`}
//                     imageHandle={lore.gallery[0].handle}
//                     imageTitle={lore.gallery[0].title}
//                   />
//                 ))}
//               </Grid>
//             </section>
//           }

//         </article>
//       </Paper>
//     );
//   }
//   return <h2>Loading tour company...</h2>;
// };

// export const singleTourCompany = gql`
//   query singleTourCompany($slug: String!) {
//     tourCompany(where: {slug: $slug}) {
//       id
//       name
//       image {
//         handle
//         title
//       }
//       description {
//         markdown
//       }
//       link
//       lores {
//         title
//         slug
//         gallery(first: 1) {
//           handle
//           title
//         }
//       }
//       places {
//         name
//         slug
//         gallery(first: 1) {
//           handle
//           title
//         }
//       }
//       people {
//         name
//         slug
//         gallery(first: 1) {
//           handle
//           title
//         }
//       }
//     }
//   }
// `;

// export default graphql(singleTourCompany, {
//   options: ({ match }) => ({
//     variables: {
//       slug: match.params.slug,
//     },
//   }),
// })(TourCompany);