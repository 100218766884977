import React from 'react';
import RelatedCard from './RelatedCard';

export default function RelatedPlaceCard(props) {
  const { place } = props;

  return (
    <RelatedCard
      title={place.name}
      link={`/place/${place.slug}`}
      imageHandle={place.gallery[0].handle}
      imageTitle={place.gallery[0].title}
    />
  );
}