import React from 'react';
import RelatedCard from './RelatedCard';

export default function RelatedPersonCard(props) {
  const { person } = props;

  return (
    <RelatedCard
      title={person.name}
      link={`/person/${person.slug}`}
      imageHandle={person.gallery[0].handle}
      imageTitle={person.gallery[0].title}
    />
  );
}